import DoNotSkipLogo from "../../static/projects/donotskip.svg"
import KindleVocabularyLogo from "../../static/projects/kindlevocabulary.png"
import {
  donotskipExperience,
  kindleVocabularyExperience,
} from "../components/about/Experiences"

export interface ProjectCardProps {
  imageSrcPath: string
  title: string
  date: string
  description: JSX.Element
  sourceURL: string
  hostedURL: string
  alt: string
}

const doNotSkip: ProjectCardProps = {
  imageSrcPath: DoNotSkipLogo,
  title: "DoNotSkip",
  date: "6/5/2020",
  description: donotskipExperience.description,
  sourceURL: "",
  hostedURL: "https://www.donotskip.com",
  alt: "DoNotSkip",
}

const kindleVocabulary: ProjectCardProps = {
  imageSrcPath: KindleVocabularyLogo,
  title: "Kindle Vocabulary Builder",
  date: "7/11/2022",
  description: kindleVocabularyExperience.description,
  sourceURL: "",
  hostedURL: "https://kindlevocabulary.com",
  alt: "Kindle Vocabulary Builder",
}

export const myProjects: ProjectCardProps[] = [doNotSkip, kindleVocabulary]
