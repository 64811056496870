import React from "react"
import { Row, Col } from "react-bootstrap"
import { ProjectCardProps } from "../../data/projects"

import MyButton from "../myButton/MyButton"

const ProjectCard: React.FC<ProjectCardProps> = (props) => {
  const { description, alt } = props

  return (
    <div className="project_container">
      <Row>
        <Col xs={12} sm={6}>
          <img
            src={props.imageSrcPath}
            alt={alt}
            style={{ maxWidth: "180px" }}
          />
        </Col>
        <div className="mx-3" />
        <Col>
          <h4>
            {props.title}
            <br />
            <small>{props.date}</small>
          </h4>
          <div>{description}</div>

          <Row style={{ justifyContent: "center" }}>
            {props.sourceURL && (
              <MyButton text={"Source Code"} URL={props.sourceURL} />
            )}
            {props.hostedURL && (
              <MyButton text={"Hosted App"} URL={props.hostedURL} />
            )}
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default ProjectCard
