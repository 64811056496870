import React from "react"
import Layout from "../components/layout/Layout"
import { Container, Row, Col } from "react-bootstrap"
import MyJumbo from "../components/myJumbo/MyJumbo"
import ProjectCard from "../components/portProjectCard/ProjectCard"
import "../index.scss"

import { myProjects } from "../data/projects"
import { Pages } from "../pages"

export default () => (
  <Layout page={Pages.PROJECTS}>
    <MyJumbo
      title={"Projects Portfolio"}
      content={
        <>
          {/* <span>
              <p>I think the best way to learn is by building Stuff.</p>
            </span> */}
        </>
      }
    />
    <Container style={{ marginTop: "2.5rem" }}>
      <Row>
        <Col>
          {myProjects.map((x) => {
            return (
              <div className="mt-3">
                <ProjectCard {...x} />
              </div>
            )
          })}
        </Col>
      </Row>
    </Container>
  </Layout>
)
